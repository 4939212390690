import React, {useState, useEffect} from "react";
import axios from "../api/axios";

const Affiliates = () => {
  const [role, setRole] = useState(localStorage.getItem('profileRole'));
  const [isLoading, setIsLoading] = useState(false);
  const [affiliates, setAffiliates] = useState([]);
  const [len, setLen] = useState(0);
  const [affiliateDetails, setAffiliateDetails] = useState([]);
  const stores = JSON.parse(localStorage.getItem('stores'));
  const [selectedStore, setSelectedStore] = useState('');
  /* setting of forms ends here */

  /* token and headers*/
  const token = localStorage.getItem('accessToken');
  if(token === '' || token === undefined || token === null){
    localStorage.setItem('accessToken', '');
    localStorage.setItem('menuAccess', []);
    localStorage.setItem('profileRole', '');
    window.location.href = '/login';
  }
  const currentActiveStoreId = localStorage.getItem('currentActiveStoreId');
  let store_id = currentActiveStoreId == '' || currentActiveStoreId == undefined ? 1: currentActiveStoreId;
  const [storeId, setStoreId] = useState(store_id);

  const userId = localStorage.getItem('userId');
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };

  useEffect(() => {
    fetchTable();
    setIsLoading(true);
  }, []);

  const fetchTable = async() => {
    const url = role.toLowerCase() === 'admin' ? '/api/order/listbystore' : '/api/order/listbystore/' + storeId;
    try{
      await axios.get(url, config ).then(result => {
        setIsLoading(false);
        setLen(result.data.data.length);
        setAffiliates(result.data.data);
        setAffiliateDetails(result.data.affiliate);
      })
    }catch(e){
      console.log(e);
      setIsLoading(false);
    }
  }

  function formatDate(date) {
    const d = new Date(date);
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
  }

  const handleChange = async (event) => {
    const storeId = event.target.value;
    setSelectedStore(storeId);
    
    if (storeId) {
      try {
        const response = await axios.get('/api/order/listbystore/' + storeId); // Replace with your API endpoint
        const data = await response.json();
        console.log(data); // Handle the API response data as needed
      } catch (error) {
        console.error('Error fetching store data:', error);
      }
    }
  };
  
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="main-kpis">
        <div className="row">
          <div className="col">
              <div className="kpi">
                  <div className="icon"><span className="menu-icon-page tf-icons bx bx-package"></span></div>
                  <div className="details"><h2>Orders</h2><p><b><span className="increase">2,653</span></b></p></div>
              </div>
          </div>
          <div className="col">
              <div className="kpi">
                  <div className="icon"><span className='menu-icon-page bx bx-line-chart' ></span></div>
                  <div className="details"><h2>Total GMV</h2><p><b><span className="increase">$ 172,445.00</span></b></p></div>
              </div>
          </div>
          <div className="col">
              <div className="kpi">
                  <div className="icon"><span className='menu-icon-page bx bx-check-square' ></span></div>
                  <div className="details"><h2>Conversion Rate</h2><p><b><span className="increase">45%</span></b></p></div>
              </div>
          </div>
          <div className="col">
              <div className="kpi">
                  <div className="icon"><i className="menu-icon-page tf-icons bx bx-money"></i></div>
                  <div className="details"><h2>Total Commissions</h2><p><b><span className="increase">$ 25,004.53</span></b></p></div>
              </div>
          </div>
          <div className="col">
              <div className="kpi">
                  <div className="icon"><b>$</b></div>
                  <div className="details"><h2>Commission Rate</h2><p><b><span className="increase">{affiliateDetails['affiliate_commission_percentage']}%</span></b></p></div>
              </div>
          </div>
        </div>         
      </div>
      {role.toLowerCase() === 'admin'&&
        <div className="main-kpis">
            <select className="form-select border border-default rounded w-20 ms-1" onChange={handleChange} value={selectedStore}>
              <option value="All">All Stores</option>
              {stores.map((store, index) => (
                <option key={index} value={store.uuid}>
                  {store.store_name}
                </option>
              ))}
            </select>
        </div>
      }

      <div className="row mb-12">
          <div id="table-list-container" className="col-md-12">
            <div className="card">
                <h5 className="card-header"><span className='bx bxs-briefcase'></span> Affiliate Commissions
                </h5>
                <div className="table-responsive text-nowrap">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Store</th>
                      <th>Product Description</th>
                      <th>Date</th>
                      <th>Recipient</th>
                      <th>Buyer</th>
                      <th className="th_decimal_values">GMV</th>
                      <th className="th_decimal_values">Rate</th>
                      <th className="th_decimal_values">Commission</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(Array.isArray(affiliates)?affiliates:[]).map(affiliate=>(
                      <tr key={affiliate.id}> 
                        <td>{affiliate.store_name}</td>
                        <td>{affiliate.product_details['description']}</td>
                        <td>{formatDate(affiliate.created_at)}</td>
                        <td>{affiliate.buyer_details['first_name'] + ' ' + affiliate.buyer_details['last_name']}</td>
                        <td>{affiliate.recipient_details['first_name'] + ' ' + affiliate.recipient_details['last_name']}</td>
                        <td className="td_decimal_values">{'$' + affiliate.gmv.toFixed(2).toLocaleString()}</td>
                        <td className="td_decimal_values">{affiliate.affiliate_commission_percentage.toFixed(1).toLocaleString() + '%'}</td>
                        <td className="td_decimal_values">{'$' + affiliate.commission.toFixed(2).toLocaleString()}</td>
                      </tr>
                    ))}
                    {len===0 && !isLoading &&
                      <tr>
                        <td colSpan={8}>No records found.</td>
                      </tr>
                    }
                    {isLoading &&
                      <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                    }
                    {isLoading &&
                      <tr><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td></tr>
                    }
                    {isLoading &&
                      <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                    }
                  </tbody>
                </table>
                </div>
            </div>
          </div>      
      </div>
    </div>
  )
}

export default Affiliates